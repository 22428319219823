let instance = null;

class Config {

    constructor() {
        this.endpoint = (process.env.NODE_ENV === "development") ? "http://localhost:3027/" : "https://cloud.onyx.dev/services";
        this.routes = "";
    }

    static instance() {
        if(instance == null) {
            instance = new Config()
        }
        return instance
    }
}

export default Config
