import { JsogService } from 'jsog-typescript';

const JSOG = new JsogService();

let instance;

class Services {

    constructor(url) {
        this.url = url
    }

    headers() {
        if(this.connection) {
            return { 
                'Content-Type': 'application/json', 
                'session_id' : this.connection.sessionId,
                'Authorization': 'Basic ' + Buffer.from(this.connection.username + ":" + this.connection.password).toString('base64')
            }
        } else {
            return { 
                'Content-Type': 'application/json', 
            }
        }
    }

    post(endpoint, parameters) {
        const requestOptions = {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify(parameters)
        };
        return fetch(this.url + "/" + endpoint, requestOptions)
                .then(response => {
                    if(response.status === 401) {
                        window.location = "/login"
                    }
                    return response.json()
                })
                .then(json => JSOG.deserialize(json))
    }
    
    put(endpoint, parameters) {
        const requestOptions = {
            method: 'PUT',
            headers: this.headers(),
            body: JSON.stringify(parameters)

        };
        return fetch(this.url + "/" + endpoint, requestOptions)
                .then(response => {
                    if(response.status === 401) {
                        window.location = "/login"
                    }
                    return response.json()
                })
                .then(json => JSOG.deserialize(json))
    }

    get(endpoint) {
        const requestOptions = {
            method: 'GET',
            headers: this.headers()
        };
        return fetch(this.url + "/" + endpoint, requestOptions)
                .then(response => {
                    if(response.status === 401) {
                        window.location = "/login"
                    }
                    return response.json()
                })
                .then(json => JSOG.deserialize(json))
    }

    delete(endpoint) {
        const requestOptions = {
            method: 'DELETE',
            headers: this.headers()
        };
        return fetch(this.url + "/" + endpoint, requestOptions)
                .then(response => response)

    }

    setConnection(connection) {
        this.connection = connection
    }

    static initialize(url) {
        instance = new Services(url);
    }

    static instance() {
        return instance;
    }

}

export default Services;
